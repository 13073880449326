import React from "react";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";

import {
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
  Button,
  Box,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  selectFooterButtonsLeft,
  selectFooterButtonsCenter,
  selectFooterButtonsRight,
  selectFooterInfoText,
} from "../redux/slices/footerSlice";
import { useTheme } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import ContractDialog from "./dialogs/ContractDialog";
import { DialogMode } from "../types/dialogmode";
import useContract from "../hooks/useContract";
import {
  useGetContractQuery,
  useUpdateContractMutation,
} from "../redux/slices/indexApiSlice";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import VendorDialog from "./dialogs/VendorDialog";
import { useGetVendorQuery } from "../redux/slices/vendorsApiSlice";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  // background: ${(props) => props.theme.footer.background};
  position: relative;
  z-index: ${(props) => props.theme.zIndex.appBar + 1};
`;

const WrapperContent = styled(Box)`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  zindex: 1199;
  py: 3;
  px: 8;
  height: 52px;
  left: 56px;
  padding-left: 16px;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.15);
  // background: ${(props) => props.theme.footer.background};
  display: flex;
  justify-content: center;
`;

const ButtonZone = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// fix a div at the bottom right of the screen
const BottomRight = styled(Box)`
  position: fixed;
  bottom: 42px;
  right: 56px;
`;

const Item = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Footer(props: { drawerBleeding: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { clientId, contractId, vendorId } = useParams();

  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const { data: contract } = useGetContractQuery(contractId ?? skipToken);

  const { data: vendor } = useGetVendorQuery(vendorId ?? skipToken);

  const backToList = () => {
    if (contract) {
      if (clientId) {
        navigate(`/contracts-under-management/${clientId}/contracts`);
      } else {
        navigate("/contracts");
      }
    } else if (vendor) {
      navigate("/vendors");
    }
  };

  return (
    <>
      {/* <BottomRight zIndex={999999}>
        <ButtonZone>
          {/* <Item>
            <AnalyzeProgressDialog />
          </Item>  
          <Item>
            <BotChat />
          </Item>
        </ButtonZone>
      </BottomRight> */}

      <Wrapper onClick={(event) => event.stopPropagation()}>
        <WrapperContent
          sx={{
            left: isDownMd ? 0 : props.drawerBleeding,
            width: isDownMd ? "100%" : `calc(100% - ${props.drawerBleeding}px)`,
            bgcolor: "background.paper",
            flexDirection: "column",
          }}
        >
          {/* <Box
          sx={{
            display: "flex",
            p: 1,
            justifyContent: "space-between",
            alignContent: "center",
            width: "100%",
          }}
        >
          {footerInfoText}
        </Box> */}

          <Box
            sx={{
              display: "flex",
              p: 1,
              justifyContent: "space-between",
              alignContent: "center",
              width: "100%",
            }}
          >
            <Item>
              {/* buttons left */}
              <ButtonZone>
                <Item key={`button-1`}>
                  <Tooltip title={t("Back")}>
                    <IconButton size="small" onClick={() => backToList()}>
                      <ArrowBack />
                      {/* {t("Back")} */}
                    </IconButton>
                  </Tooltip>
                </Item>

                {contractId && contract && (
                  <Item key={`button-contract-edit`}>
                    <ContractDialog
                      contract={contract}
                      mode={DialogMode.Edit}
                      iconOnly
                    />
                  </Item>
                )}

                {vendorId && vendor && (
                  <Item key={`button-vendor-edit`}>
                    <VendorDialog
                      vendor={vendor}
                      mode={DialogMode.Edit}
                      iconOnly
                    />
                  </Item>
                )}

                {contractId && contract && (
                  <Item key={`button-contract-delete`}>
                    <ContractDialog
                      contract={contract}
                      mode={DialogMode.Delete}
                      iconOnly
                    />
                  </Item>
                )}

                {vendorId && vendor && (
                  <Item key={`button-vendor-delete`}>
                    <VendorDialog
                      vendor={vendor}
                      mode={DialogMode.Delete}
                      iconOnly
                    />
                  </Item>
                )}
              </ButtonZone>
            </Item>

            <Item>
              {/* buttons center */}
              <ButtonZone />
            </Item>

            <Item pr={4}>{/* buttons right */}</Item>
          </Box>
        </WrapperContent>
      </Wrapper>
    </>
  );
}

export default Footer;
