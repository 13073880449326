import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card as MuiCard,
  CardActionArea,
  CardActions,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Breadcrumbs as MuiBreadcrumbs,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Tooltip,
  Typography,
  Pagination,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TablePagination,
  AlertTitle,
  Avatar,
  Collapse,
  CircularProgress,
  CardHeader,
  Skeleton,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { css } from "@emotion/react";
import { rgba } from "polished";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useGetConnectedDrivesQuery } from "../../redux/slices/indexApiSlice";

import { useTranslation } from "react-i18next";
import { IDocument } from "../../types/document";

import {
  useGetDriveQuery,
  useGetOutlookMessageAttachmentsQuery,
  useGetSiteQuery,
  useLazyGetTeamDriveItemChildrenQuery,
  useLazyGetTeamDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import { ISharePointConnection } from "../../types/sharepointconnection";
import useAuth from "../../hooks/useAuth";
import { connect } from "formik";
import { IDriveItem } from "../../types/driveItem";
import OneDriveIcon from "../../icons/OneDriveIcon";
import SharePointIcon from "../../icons/SharePointIcon";
import MSTeamsIcon from "../../icons/MSTeamsIcon";
import OutlookIcon from "../../icons/OutlookIcon";
import { format } from "date-fns";
import DateTimeTranslate from "../DateTimeTranslate";
import { IOutlookMessage } from "../../types/outlookmessage";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";

import { DialogMode } from "../../types/dialogmode";
import { Folder as FolderIcon } from "react-feather";
import FluentUIFileTypeIcon from "../../icons/FluentUIFileTypeIcon";
import { IConnectedDrive } from "../../types/connecteddrive";
import ConnectedSharePointDriveDialog from "./ConnectedSharePointDriveDialog";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  Delete,
  Maximize,
  OpenInBrowser,
  OpenInNew,
} from "@mui/icons-material";
import ConnectedDriveCard from "../cards/ConnectedDriveCard";
const mimeDb = require("mime-db");

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const illustrationCardStyle = (props: any) => css`
  ${props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  // margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

function getFileTypeName(mimeType: string) {
  const mimeEntry = mimeDb[mimeType];

  if (mimeEntry && mimeEntry.extensions) {
    const fileExtension = mimeEntry.extensions[0];
    return fileExtension.toUpperCase();
  }

  return "Unknown";
}

interface IDriveItemCardProps {
  driveItem: any;
  onClick?: () => void;
}

interface ISharePointConnectionRowProps {
  connection: ISharePointConnection;
  onClick: (connection: ISharePointConnection) => void;
}

const SharePointConnectionRow = ({
  ...props
}: ISharePointConnectionRowProps) => {
  const connection = props.connection;

  const { data: site } = useGetSiteQuery(connection.siteId);
  const { data: drive } = useGetDriveQuery(connection.driveId);

  return (
    <>
      <TableCell component="th" scope="row">
        {site?.displayName}
      </TableCell>
      <TableCell>{drive?.name}</TableCell>
    </>
  );
};

interface IOutlookMessageRowProps {
  message: IOutlookMessage;
  onClick: (message: IOutlookMessage) => void;
}
const OutlookMessageRow = ({ ...props }: IOutlookMessageRowProps) => {
  const message = props.message;

  // const { data: site } = useGetSiteQuery({ siteId: message.siteId });
  // const { data: drive } = useGetDriveQuery({ driveId: message.driveId });

  return (
    <>
      <TableCell component="th" scope="row">
        {message?.receivedDateTime &&
          format(new Date(message?.receivedDateTime), "P")}
      </TableCell>
      <TableCell>
        {message?.from?.emailAddress?.name}{" "}
        {`<${message?.from?.emailAddress?.address}>`}
      </TableCell>
      <TableCell>{message?.subject}</TableCell>
    </>
  );
};

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
}

function sharepointConnectionsToDriveItems(
  connection: ISharePointConnection,
  driveItems: IDriveItem[] = []
) {
  return {
    connection,
    driveItems,
  };
}

function outlookMessagesToAttachments(
  message: IOutlookMessage,
  attachments: IDriveItem[] = []
) {
  return {
    message,
    attachments,
  };
}

function OneDriveRow(props: {
  driveItem: IDriveItem;
  source: Source;
  selectedDriveItem: IDriveItem;
  setSelectedDriveItem: React.Dispatch<
    React.SetStateAction<IDriveItem | undefined>
  >;
}) {
  const { t } = useTranslation();

  const { driveItem, source, selectedDriveItem, setSelectedDriveItem } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      setSelectedDriveItem(selectedDriveItem);
    }
  }, [open]);

  const handleConnectionClick = () => {
    setOpen(!open);
  };

  return (
    <TableRow
      hover
      key={driveItem.id}
      selected={driveItem.id === selectedDriveItem?.id}
      onClick={() => {
        setSelectedDriveItem(driveItem);
      }}
      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="none">
        <ListItem>
          <ListItemIcon>
            <FluentUIFileTypeIcon filename={driveItem.name} />
          </ListItemIcon>
          <ListItemText>{driveItem.name}</ListItemText>
        </ListItem>
      </TableCell>
      <TableCell>
        {driveItem.modified && (
          <Typography>{format(new Date(driveItem.modified), "P")}</Typography>
        )}
      </TableCell>
      <TableCell>{driveItem.sizeFriendly}</TableCell>
    </TableRow>
  );
}

// function SharePointRow(props: {
//   connection: ISharePointConnection;
//   source: Source;
//   selectedDriveItem: IDriveItem;
//   setSelectedDriveItem: React.Dispatch<
//     React.SetStateAction<IDriveItem | undefined>
//   >;
// }) {
//   const { t } = useTranslation();

//   const { connection, source, selectedDriveItem, setSelectedDriveItem } = props;
//   const [open, setOpen] = React.useState(false);

//   const [selectedConnection, setSelectedConnection] =
//     useState<ISharePointConnection>();

//   const {
//     data: driveItems = [],
//     isLoading,
//     isSuccess: driveItemsLoaded,
//     refetch: refetchSharePointConnections,
//     isError: driveItemsError,
//     error: driveItemsErrorObject,
//   } = useGetDriveItemsQuery();

//   useEffect(() => {
//     if (open) {
//       setSelectedConnection(connection);
//     }
//   }, [open]);

//   const handleConnectionClick = () => {
//     setOpen(!open);
//   };

//   return (
//     <React.Fragment>
//       <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
//         <TableCell>
//           <IconButton
//             aria-label="expand row"
//             size="small"
//             onClick={handleConnectionClick}
//           >
//             {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
//           </IconButton>
//         </TableCell>
//         <SharePointConnectionRow
//           connection={connection}
//           onClick={setSelectedConnection}
//         />
//       </TableRow>
//       <TableRow>
//         <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//           <Collapse in={open} timeout="auto" unmountOnExit>
//             <Box sx={{ margin: 1 }}>
//               site avatar
//               <br />
//               site url met open link
//               <br />
//               {isLoading ? (
//                 <CircularProgress sx={{ padding: 2 }} />
//               ) : (
//                 <Table size="small" stickyHeader>
//                   <TableHead>
//                     <TableRow>
//                       <TableCell>{t("File name")}</TableCell>
//                       <TableCell>{t("Modified")}</TableCell>
//                       <TableCell>{t("Size")}</TableCell>
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {driveItems?.map((driveItem: IDriveItem) => (
//                       <TableRow
//                         hover
//                         key={driveItem.id}
//                         selected={driveItem.id === selectedDriveItem?.id}
//                         onClick={() => {
//                           setSelectedDriveItem(driveItem);
//                         }}
//                         sx={{ cursor: "pointer" }}
//                       >
//                         <TableCell padding="none">
//                           <ListItem>
//                             <ListItemIcon>
//                               <FluentUIFileTypeIcon filename={driveItem.name} />
//                             </ListItemIcon>
//                             <ListItemText>{driveItem.name}</ListItemText>
//                           </ListItem>
//                         </TableCell>
//                         <TableCell>
//                           {driveItem.modified && (
//                             <Typography>
//                               {format(new Date(driveItem.modified), "P")}
//                             </Typography>
//                           )}
//                         </TableCell>
//                         <TableCell>{driveItem.sizeFriendly}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               )}
//             </Box>
//           </Collapse>
//         </TableCell>
//       </TableRow>
//     </React.Fragment>
//   );
// }

function TeamsRow(props: {
  team: any;
  selectedDriveItems: IDriveItem;
  setSelectedDriveItems: React.Dispatch<
    React.SetStateAction<IDriveItem | undefined>
  >;
}) {
  const { t } = useTranslation();

  const { team, selectedDriveItems, setSelectedDriveItems } = props;
  const [open, setOpen] = React.useState(false);

  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [selectedChannel, setSelectedChannel] = useState<any>();

  const [getTeamDriveItems, { data: driveItems = [] }] =
    useLazyGetTeamDriveItemsQuery();

  const [getTeamDriveItemChildren, { data: driveItemChildren = [] }] =
    useLazyGetTeamDriveItemChildrenQuery();

  useEffect(() => {
    if (selectedChannel?.id) {
      getTeamDriveItemChildren({
        teamId: selectedTeam?.id,
        driveItemId: selectedChannel?.id,
      });
    }
  }, [selectedTeam?.id, selectedChannel?.id]);

  useEffect(() => {
    if (open) {
      getTeamDriveItems(team?.id);
    }
  }, [open]);

  const handleConnectionClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleConnectionClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {team?.displayName}
        </TableCell>
        <TableCell>{team?.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom pt={2}>
                {t("Channels")}
              </Typography>
              <Table size="small">
                <TableBody>
                  {driveItems?.map((driveItem: IDriveItem) => (
                    <>
                      <TableRow
                        hover
                        key={driveItem.id}
                        // selected={driveItem.id === selectedDriveItem?.id}
                        // onClick={() => getTeamDriveItemChildren(driveItem.id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="none">
                          <ListItem>
                            <ListItemIcon>
                              {driveItem.folder ? (
                                <FolderIcon />
                              ) : (
                                <FluentUIFileTypeIcon
                                  filename={driveItem.name}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText>{driveItem.name}</ListItemText>
                          </ListItem>
                        </TableCell>
                        <TableCell>
                          {driveItem.modified && (
                            <Typography>
                              {format(new Date(driveItem.modified), "P")}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>{driveItem.sizeFriendly}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{t("File name")}</TableCell>
                                    <TableCell>{t("Size")}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {driveItemChildren?.map(
                                    (driveItem: IDriveItem) => (
                                      <TableRow
                                        hover
                                        key={driveItem.id}
                                        selected={
                                          driveItem.id ===
                                          selectedDriveItems?.id
                                        }
                                        onClick={() => {
                                          setSelectedDriveItems((prev) =>
                                            prev?.id === driveItem.id
                                              ? undefined
                                              : driveItem
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell padding="none">
                                          <ListItem>
                                            <ListItemIcon>
                                              <FluentUIFileTypeIcon
                                                filename={driveItem.name}
                                              />
                                            </ListItemIcon>
                                            <ListItemText>
                                              {driveItem.name}
                                            </ListItemText>
                                          </ListItem>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                          {driveItem.sizeFriendly}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function OutlookRow(props: {
  message: IOutlookMessage;
  source: Source;
  selectedAttachment: IOutlookMessageAttachment;
  setSelectedAttachment: React.Dispatch<
    React.SetStateAction<IOutlookMessageAttachment | undefined>
  >;
  setSelectedMessage: React.Dispatch<
    React.SetStateAction<IOutlookMessage | undefined>
  >;
}) {
  const { t } = useTranslation();

  const {
    message,
    source,
    selectedAttachment,
    setSelectedAttachment,
    setSelectedMessage,
  } = props;
  const [open, setOpen] = React.useState(false);

  const {
    data: outlookAttachments = [],
    refetch: getOutlookAttachments,
    isUninitialized: outlookAttachmentsUnitialized,
  } = useGetOutlookMessageAttachmentsQuery(message?.id, { skip: !message?.id });

  useEffect(() => {
    if (open) {
      setSelectedMessage(message);
    }
  }, [open]);

  const handleMessageClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleMessageClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <OutlookMessageRow message={message} onClick={setSelectedMessage} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("File name")}</TableCell>
                    <TableCell>{t("Size")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outlookAttachments?.map(
                    (outlookAttachment: IOutlookMessageAttachment) => (
                      <TableRow
                        hover
                        key={outlookAttachment.id}
                        selected={
                          outlookAttachment.id === selectedAttachment?.id
                        }
                        onClick={() => {
                          setSelectedAttachment(outlookAttachment);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="none">
                          <ListItem>
                            <ListItemIcon>
                              <FluentUIFileTypeIcon
                                filename={outlookAttachment.name}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {outlookAttachment.name}
                            </ListItemText>
                          </ListItem>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>{outlookAttachment.sizeFriendly}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// define the sources as a number enum
enum Source {
  OneDrive = 1,
  SharedWithMe = 2,
  SharePoint = 3,
  Teams = 4,
  Outlook = 5,
}

interface IConnectDriveDialogProps {
  renderButton?: JSX.Element;
  onSuccess?: (newDrive: IConnectedDrive) => void;
  open?: boolean;
  onClose?: () => void;
}

function ConnectDriveDialog({ ...props }: IConnectDriveDialogProps) {
  const { t } = useTranslation();

  const {
    data: connectedDrives = [],
    isSuccess: connectedDrivesLoaded,
    refetch: refetchConnectedDrives,
    isError: connectedDrivesError,
    error: connectedDrivesErrorObject,
  } = useGetConnectedDrivesQuery();

  const handleCancelClick = () => {
    props.onClose && props.onClose();
  };

  return (
    <>
      <Dialog
        open={props.open || false}
        maxWidth="lg"
        fullWidth
        fullScreen
        sx={{ padding: 10, marginX: 30, marginY: 15 }}
        onClose={() => props.onClose}
        aria-labelledby="form-dialog-title"
        // PaperProps={{
        //   style: {
        //     minHeight: "90vh",
        //   },
        // }}
      >
        <DialogTitle id="form-dialog-title">
          {t("New connected drive")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Upload a new document")}</DialogContentText>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid>
              <Grid container gap={3} flexDirection="column">
                {connectedDrivesError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(connectedDrivesErrorObject)}
                    </Alert>
                  </Grid>
                )}

                <Grid>
                  {/* <Typography variant="body1" gutterBottom>
                      {t("No documents found")}
                    </Typography> */}

                  <ConnectedSharePointDriveDialog
                    mode={DialogMode.Add}
                    refetch={refetchConnectedDrives}
                  />
                </Grid>

                <Grid>
                  {connectedDrives?.map((connectedDrive: IConnectedDrive) => (
                    <ConnectedDriveCard
                      key={connectedDrive.id}
                      connectedDrive={connectedDrive}
                    />
                  ))}
                </Grid>

                <Grid>
                  {/* <Pagination
                    // siblingCount={0}
                    // boundaryCount={0}
                    count={numPages}
                    color="secondary"
                    page={page}
                    onChange={handleChange}
                  /> */}
                </Grid>
              </Grid>

              {/* <Grid lg={6}>
              {selectedDocumentUrl && (
                <DocumentViewer
                  file={null}
                  arrayBuffer={null}
                  document={null}
                  driveItem={selectedDriveItem}
                  hideFileMenu
                  fileUrl={selectedDocumentUrl}
                  defaultScale={1.0}
                  height="56vh"
                  viewMode="pageOnly"
                />
              )}
            </Grid> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={() => console.log("clicked")} variant="contained">
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConnectDriveDialog;
