import React, { useEffect, useState } from "react";

import { IVendor } from "../../types/vendor";
import { Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AvatarGroup,
  createFilterOptions,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DatePicker } from "@mui/x-date-pickers";
import { DialogMode } from "../../types/dialogmode";
import useAuth from "../../hooks/useAuth";
import { INote } from "../../types/note";
import { IGraphUser } from "../../types/user";
import { useTranslation } from "react-i18next";
import { IContract } from "../../types/contract";
import SearchUserDialog from "../dialogs/SearchUserDialog";
import AzureADAvatar from "../avatars/AzureADAvatar";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchemaNote = Yup.object().shape({
  body: Yup.string().required("Required"),
  // audience: Yup.string().required("Required"),
  reminderDate: Yup.date().optional(),
  // created: Yup.date().optional(),
});

interface INoteFormProps {
  mode: DialogMode;
  type: "note" | "reminder";
  addNote: (note: INote) => Promise<void>;
  updateNote: (note: INote) => Promise<void>;
  contract?: IContract;
  vendor?: IVendor;
  selectedUserIds?: string[];
  note?: INote;
  users?: IGraphUser[];
}

function NoteForm({ ...props }: INoteFormProps) {
  const { t } = useTranslation();
  // create a ref
  // const formikRef = useRef<FormikProps<any>>(null);

  const { user } = useAuth();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === "add") {
        await props.addNote(values);

        //   resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === "edit") {
        await props.updateNote(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: "Mode undefined" });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.Note });
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   if (props.submit) {
  //     // if (formikRef.current) {
  //     //   // submit the form
  //     //   formikRef?.current?.handleSubmit();
  //     // }
  //   }
  // }, [props.submit]);

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const blankNote: INote = {
    id: "",
    body: "",
    reminderDate: undefined,
    contractId: props.contract?.id,
    vendorId: props.vendor?.id,
    audience: "everyone",
    author: user?.id,
    taggedUsers: props.selectedUserIds ?? [],
  };

  const initialValues: INote = props.note ? props.note : blankNote;

  const userList =
    props.users?.map((user: IGraphUser) => {
      return { id: user.id, name: user.displayName, email: user.mail };
    }) || [];

  const fieldVariant = "standard"; // "outlined";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaNote}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6} gap={3}>
          {errors && !isValid && !status?.sent && touched.body && (
            <Grid>
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            </Grid>
          )}

          <Grid width="100%">
            <Form id="note-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid>
                  <FormControl fullWidth>
                    <Field
                      name="reminderDate"
                      error={Boolean(
                        touched.reminderDate && errors.reminderDate
                      )}
                      helperText={
                        touched.reminderDate && errors.reminderDate
                          ? errors.reminderDate
                          : null
                      }
                    >
                      {() => {
                        const reminderDate = values.reminderDate
                          ? new Date(values.reminderDate)
                          : null;

                        return (
                          <DatePicker
                            label={t("Date")}
                            value={reminderDate}
                            onChange={(date: any) =>
                              setFieldValue("reminderDate", date)
                            }
                            // onError={(newError) => {
                            //   setDateError(newError);
                            //   setFieldError("reminderDate", dateErrorMessage);
                            // }}
                            // minDate={dayjs(new Date())}
                            slotProps={{
                              textField: {
                                // helperText:
                                //   dateError === "maxDate"
                                //     ? dateErrorMessage
                                //     : null,
                                // error: dateError === "maxDate",
                                // error: Boolean(touched.endDate && errors.endDate),
                                variant:
                                  touched.reminderDate && errors.reminderDate
                                    ? errorFieldVariant
                                    : fieldVariant,
                                fullWidth: true,
                              },
                            }}
                          />
                        );
                      }}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid>
                  <Grid container direction="row">
                    <Grid size={{ sm: 12, md: 8 }}>
                      {userList.length > 0 && (
                        <Autocomplete
                          multiple
                          fullWidth
                          id="tagged-users-select"
                          options={userList}
                          getOptionLabel={
                            (person: any) => `${person.name}` // (${person.email})
                          }
                          // defaultValue={userList.filter((person: IGraphUser) => values?.taggedUsers?.includes(person.id))}
                          value={userList.filter((person) =>
                            values?.taggedUsers?.includes(person.id)
                          )}
                          onChange={(event, selectedPeople) => {
                            setFieldValue(
                              "taggedUsers",
                              selectedPeople?.map((person: any) => person.id)
                            );
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label={t("Select People")}
                              variant={
                                touched.taggedUsers && errors.taggedUsers
                                  ? errorFieldVariant
                                  : fieldVariant
                              }
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid size={{ sm: 12, md: 4 }}>
                      <SearchUserDialog
                        mode={DialogMode.Edit}
                        selectedUserIds={values.taggedUsers}
                        setSelectedUserIds={(selected: string[]) =>
                          setFieldValue("taggedUsers", selected)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid>
                  <TextField
                    name="body"
                    label={t("Note")}
                    value={values.body}
                    error={Boolean(touched.body && errors.body)}
                    fullWidth
                    // helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.body && errors.body
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    minRows={3}
                    maxRows={5}
                  />
                </Grid>

                {/* <Box mt={3}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Assign target audience</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.audience === "everyone"}
                            onChange={() => setFieldValue("audience", "everyone")}
                            name="audience"
                            value={values.audience}
                          />
                        }
                        label="Everyone"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.audience === "procurementdept"}
                            onChange={() => setFieldValue("audience", "procurementdept")}
                            name="audience"
                            value={values.audience}
                          />
                        }
                        label="Procurement Department"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={values.audience === "management"}
                            onChange={() => setFieldValue("audience", "management")}
                            name="audience"
                            value={values.audience}
                          />
                        }
                        label="Management"
                      />
                    </FormGroup>
                  </FormControl>
                </Box> */}

                {/* <Button type="submit" variant="contained" color="primary" mt={3}>
                    Save changes
                  </Button> */}
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default NoteForm;
