import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card as MuiCard,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Breadcrumbs as MuiBreadcrumbs,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Tooltip,
  Typography,
  Pagination,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TablePagination,
  AlertTitle,
  Avatar,
  Collapse,
  CircularProgress,
  ListItemSecondaryAction,
  Checkbox,
  ListItemAvatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Download as DownloadIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

import { css } from "@emotion/react";
import { rgba } from "polished";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useGetSharePointConnectionsQuery } from "../../redux/slices/indexApiSlice";

import { useTranslation } from "react-i18next";
import { IDocument } from "../../types/document";

import {
  useGetDriveQuery,
  useGetSiteQuery,
  useLazyGetDriveItemsQuery,
  useLazyGetJoinedTeamsQuery,
  useLazyGetOutlookMessageAttachmentsQuery,
  useLazyGetOutlookMessagesCountQuery,
  useLazyGetOutlookMessagesQuery,
  useLazyGetRecentOneDriveFilesQuery,
  useLazyGetSharedWithMeFilesQuery,
  useLazyGetTeamDriveItemChildrenQuery,
  useLazyGetTeamDriveItemsQuery,
} from "../../redux/slices/graphApiSlice";
import { ISharePointConnection } from "../../types/sharepointconnection";
import useAuth from "../../hooks/useAuth";
import { connect } from "formik";
import { IDriveItem } from "../../types/driveItem";
import OneDriveIcon from "../../icons/OneDriveIcon";
import SharePointIcon from "../../icons/SharePointIcon";
import MSTeamsIcon from "../../icons/MSTeamsIcon";
import OutlookIcon from "../../icons/OutlookIcon";
import { format } from "date-fns";
import DateTimeTranslate from "../DateTimeTranslate";
import { IOutlookMessage } from "../../types/outlookmessage";
import { IOutlookMessageAttachment } from "../../types/outlookattachment";
import SharePointConnectionDialog from "./SharePointConnectionDialog";
import { DialogMode } from "../../types/dialogmode";
import { ChevronDown, Eye, Folder as FolderIcon } from "react-feather";
import FluentUIFileTypeIcon from "../../icons/FluentUIFileTypeIcon";
import DocumentViewer from "../viewers/DocumentViewer";
import PDFViewer from "../viewers/PDFViewer";

const mimeDb = require("mime-db");

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const illustrationCardStyle = (props: any) => css`
  ${props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  // margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

function getFileTypeName(mimeType: string) {
  const mimeEntry = mimeDb[mimeType];

  if (mimeEntry && mimeEntry.extensions) {
    const fileExtension = mimeEntry.extensions[0];
    return fileExtension.toUpperCase();
  }

  return "Unknown";
}

interface IDriveItemCardProps {
  driveItem: any;
  onClick?: () => void;
}

interface ITeamsTeamRowProps {
  team: any;
  onClick: (team: any) => void;
}

const TeamsTeamRow = ({ ...props }: ITeamsTeamRowProps) => {
  const team = props.team;

  return (
    <>
      <TableCell component="th" scope="row">
        {team?.displayName}
      </TableCell>
      <TableCell>{team?.description}</TableCell>
    </>
  );
};

interface IOutlookMessageRowProps {
  message: IOutlookMessage;
  onClick: (message: IOutlookMessage) => void;
}
const OutlookMessageRow = ({ ...props }: IOutlookMessageRowProps) => {
  const message = props.message;

  // const { data: site } = useGetSiteQuery({ siteId: message.siteId });
  // const { data: drive } = useGetDriveQuery({ driveId: message.driveId });

  return (
    <>
      <TableCell component="th" scope="row">
        {message?.receivedDateTime &&
          format(new Date(message?.receivedDateTime), "P")}
      </TableCell>
      <TableCell>
        {message?.from?.emailAddress?.name}{" "}
        {`<${message?.from?.emailAddress?.address}>`}
      </TableCell>
      <TableCell>{message?.subject}</TableCell>
    </>
  );
};

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
}

function sharepointConnectionsToDriveItems(
  connection: ISharePointConnection,
  driveItems: IDriveItem[] = []
) {
  return {
    connection,
    driveItems,
  };
}

function outlookMessagesToAttachments(
  message: IOutlookMessage,
  attachments: IDriveItem[] = []
) {
  return {
    message,
    attachments,
  };
}

const DriveItemHeader = ({
  onSelectAll,
  onUnselectAll,
  isAllSelected,
}: {
  onSelectAll: () => void;
  onUnselectAll: () => void;
  isAllSelected: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            inputProps={{
              "aria-label": "select all items",
            }}
            onChange={(e, checked) => {
              if (!checked) {
                onUnselectAll();
              } else {
                onSelectAll();
              }
            }}
            checked={isAllSelected}
          />
        </TableCell>
        <TableCell>{t("File name")}</TableCell>
        <TableCell>{t("Modified")}</TableCell>
        {/* <TableCell>{t("Size")}</TableCell> */}
      </TableRow>
    </TableHead>
  );
};

type DriveItemRowProps = {
  driveItem: IDriveItem;
  source: Source;
  isSelected: boolean;
  setSelectedDriveItems: React.Dispatch<React.SetStateAction<IDriveItem[]>>;
};

function DriveItemRow(props: DriveItemRowProps) {
  const { t } = useTranslation();

  const { driveItem, source, isSelected, setSelectedDriveItems } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      // setSelectedDriveItems((prev) => [...(prev ?? []), driveItem]);
    }
  }, [open]);

  const handleRowClick = () => {
    if (isSelected) {
      setSelectedDriveItems((prev) =>
        prev.filter((item) => item.id !== driveItem.id)
      );
    } else {
      setSelectedDriveItems((prev) => [...prev, driveItem]);
    }
  };

  return (
    <TableRow
      hover
      key={driveItem.id}
      selected={isSelected}
      onClick={handleRowClick}
      sx={{ cursor: "pointer" }}
    >
      {/* /add a cell for the checkbox */}
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          color="primary"
          inputProps={{
            "aria-label": `select ${driveItem.name}`,
          }}
        />
      </TableCell>

      <TableCell padding="none">
        <ListItem>
          <ListItemIcon>
            <FluentUIFileTypeIcon filename={driveItem.name} />
          </ListItemIcon>
          <ListItemText>{driveItem.name}</ListItemText>
        </ListItem>
      </TableCell>
      <TableCell>
        {driveItem.modified && (
          <DateTimeTranslate
            date={new Date(driveItem.modified)}
            format="relative"
          />
        )}
      </TableCell>
      {/* <TableCell>{driveItem.sizeFriendly}</TableCell> */}
    </TableRow>
  );
}

function SharePointRow(props: {
  connection: ISharePointConnection;
  source: Source;
  setSelectedDriveItems: React.Dispatch<React.SetStateAction<IDriveItem[]>>;
  selectedDriveItems: IDriveItem[];
}) {
  const { t } = useTranslation();
  const { selectedDriveItems, setSelectedDriveItems, source, connection } =
    props;

  const [open, setOpen] = React.useState(false);
  const { data: site } = useGetSiteQuery(connection?.siteId, {
    skip: !connection?.siteId,
  });
  const { data: drive } = useGetDriveQuery(connection?.driveId, {
    skip: !connection?.driveId,
  });

  const [getDriveItems, { data: driveItems = [], isLoading }] =
    useLazyGetDriveItemsQuery();

  useEffect(() => {
    if (open) {
      if (connection?.siteId && connection?.driveId) {
        getDriveItems(connection?.driveId);
      }
    }
  }, [open]);

  const handleConnectionClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleConnectionClick}
            sx={{
              transform: open ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {site?.displayName}
        </TableCell>
        <TableCell>{drive?.name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {site?.displayName}

              <br />
              {site?.webUrl}
              <br />
              {isLoading ? (
                <CircularProgress sx={{ padding: 2 }} />
              ) : (
                <Table size="small" stickyHeader>
                  <DriveItemHeader
                    onSelectAll={() =>
                      setSelectedDriveItems((prev) => [...prev, ...driveItems])
                    }
                    onUnselectAll={() =>
                      setSelectedDriveItems((prev) =>
                        prev.filter((item) => !driveItems.includes(item))
                      )
                    }
                    isAllSelected={driveItems.every((item) =>
                      selectedDriveItems.includes(item)
                    )}
                  />
                  <TableBody>
                    {driveItems?.map((driveItem: IDriveItem, i: number) => (
                      <DriveItemRow
                        key={`driveitem-${i}`}
                        driveItem={driveItem}
                        source={source!}
                        isSelected={selectedDriveItems.some(
                          (s) => s.id === driveItem.id
                        )}
                        setSelectedDriveItems={setSelectedDriveItems}
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TeamsRow(props: {
  team: any;
  source: Source;
  isSelected: boolean;
  setSelectedDriveItems: React.Dispatch<React.SetStateAction<IDriveItem[]>>;
}) {
  const { t } = useTranslation();

  const { team, source, isSelected, setSelectedDriveItems } = props;
  const [open, setOpen] = React.useState(false);

  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [selectedChannel, setSelectedChannel] = useState<any>();

  const [getTeamDriveItems, { data: driveItems = [] }] =
    useLazyGetTeamDriveItemsQuery();

  const [getTeamDriveItemChildren, { data: driveItemChildren = [] }] =
    useLazyGetTeamDriveItemChildrenQuery();

  useEffect(() => {
    if (selectedChannel?.id) {
      getTeamDriveItemChildren({
        teamId: selectedTeam?.id,
        driveItemId: selectedChannel?.id,
      });
    }
  }, [selectedTeam?.id, selectedChannel?.id]);

  useEffect(() => {
    if (open) {
      setSelectedTeam(team);
    }
  }, [open]);

  const handleConnectionClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleConnectionClick}
            sx={{
              transform: open ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </TableCell>
        <TeamsTeamRow team={team} onClick={setSelectedTeam} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom pt={2}>
                {t("Channels")}
              </Typography>
              <Table size="small">
                <TableBody>
                  {driveItems?.map((driveItem: IDriveItem) => (
                    <>
                      <TableRow
                        hover
                        key={driveItem.id}
                        selected={isSelected}
                        onClick={() => {
                          driveItem.folder
                            ? setSelectedChannel(driveItem)
                            : setSelectedDriveItems((prev) => [
                                ...prev,
                                driveItem,
                              ]);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="none">
                          <ListItem>
                            <ListItemIcon>
                              {driveItem.folder ? (
                                <FolderIcon />
                              ) : (
                                <FluentUIFileTypeIcon
                                  filename={driveItem.name}
                                />
                              )}
                            </ListItemIcon>
                            <ListItemText>{driveItem.name}</ListItemText>
                          </ListItem>
                        </TableCell>
                        <TableCell>
                          {driveItem.modified && (
                            <Typography>
                              {format(new Date(driveItem.modified), "P")}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>{driveItem.sizeFriendly}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{t("File name")}</TableCell>
                                    <TableCell>{t("Size")}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {driveItemChildren?.map(
                                    (driveItem: IDriveItem) => (
                                      <TableRow
                                        hover
                                        key={driveItem.id}
                                        selected={isSelected}
                                        onClick={() => {
                                          setSelectedDriveItems((prev) => [
                                            ...prev,
                                            driveItem,
                                          ]);
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <TableCell padding="none">
                                          <ListItem>
                                            <ListItemIcon>
                                              <FluentUIFileTypeIcon
                                                filename={driveItem.name}
                                              />
                                            </ListItemIcon>
                                            <ListItemText>
                                              {driveItem.name}
                                            </ListItemText>
                                          </ListItem>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                          {driveItem.sizeFriendly}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type OutlookMessageAttachmentRowProps = {
  attachment: IOutlookMessageAttachment;
  isSelected: boolean;
  setSelectedAttachments: React.Dispatch<
    React.SetStateAction<IOutlookMessageAttachment[]>
  >;
};

function OutlookMessageAttachmentRow(props: OutlookMessageAttachmentRowProps) {
  const { t } = useTranslation();

  const { attachment, isSelected, setSelectedAttachments } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      // setSelectedDriveItems((prev) => [...(prev ?? []), driveItem]);
    }
  }, [open]);

  const handleRowClick = () => {
    if (isSelected) {
      setSelectedAttachments((prev) =>
        prev.filter((item) => item.id !== attachment.id)
      );
    } else {
      setSelectedAttachments((prev) => [...prev, attachment]);
    }
  };

  return (
    <TableRow
      hover
      key={attachment.id}
      selected={isSelected}
      onClick={handleRowClick}
      sx={{ cursor: "pointer", "& > *": { borderBottom: "unset" } }}
    >
      {/* /add a cell for the checkbox */}
      <TableCell padding="checkbox">
        <Checkbox
          checked={isSelected}
          color="primary"
          inputProps={{
            "aria-label": `select ${attachment.name}`,
          }}
        />
      </TableCell>

      <TableCell padding="none">
        <ListItem>
          <ListItemIcon>
            <FluentUIFileTypeIcon filename={attachment.name} />
          </ListItemIcon>
          <ListItemText>{attachment.name}</ListItemText>
        </ListItem>
      </TableCell>
    </TableRow>
  );
}

function OutlookRow(props: {
  message: IOutlookMessage;
  selectedAttachments: IOutlookMessageAttachment[];
  setSelectedAttachments: React.Dispatch<
    React.SetStateAction<IOutlookMessageAttachment[]>
  >;
}) {
  const { t } = useTranslation();

  const { message, selectedAttachments, setSelectedAttachments } = props;

  const [open, setOpen] = React.useState(false);

  const [getAttachments, { data: outlookAttachments = [], isLoading }] =
    useLazyGetOutlookMessageAttachmentsQuery();

  useEffect(() => {
    if (open && message?.id) {
      getAttachments(message.id);
    }
  }, [open]);

  const handleMessageClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleMessageClick}
            sx={{
              transform: open ? "rotate(90deg)" : "rotate(0deg)",
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {message?.receivedDateTime &&
            format(new Date(message?.receivedDateTime), "P")}
        </TableCell>
        <TableCell>
          {message?.from?.emailAddress?.name}{" "}
          {`<${message?.from?.emailAddress?.address}>`}
        </TableCell>
        <TableCell>{message?.subject}</TableCell>
      </TableRow>

      {message?.bodyPreview && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
            colSpan={4}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 3 }}>
                <Typography variant="body1">{message?.bodyPreview}</Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 3 }}>
              {isLoading ? (
                <CircularProgress sx={{ padding: 2 }} />
              ) : (
                <Table size="small" stickyHeader>
                  <TableBody
                    sx={{
                      "& > :last-child": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    {outlookAttachments?.map(
                      (
                        outlookAttachment: IOutlookMessageAttachment,
                        i: number
                      ) => (
                        <OutlookMessageAttachmentRow
                          key={`outlookattachmant-${i}`}
                          attachment={{
                            ...outlookAttachment,
                            messageId: message.id, // make sure to add the message id to the attachment
                          }}
                          isSelected={selectedAttachments.some(
                            (s) => s.id === outlookAttachment.id
                          )}
                          setSelectedAttachments={setSelectedAttachments}
                        />
                      )
                    )}
                  </TableBody>
                </Table>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// define the sources as a number enum
enum Source {
  OneDrive = 1,
  SharedWithMe = 2,
  SharePoint = 3,
  Teams = 4,
  Outlook = 5,
}

interface IImportDialogProps {
  renderButton?: JSX.Element;
  onSuccess?: (documents: Omit<IDocument, "contractId">[]) => void;
  open?: boolean;
  onClose?: () => void;
}

function ImportDialog({ ...props }: IImportDialogProps) {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [source, setSource] = useState<Source>();
  const [selectedMessageAttachments, setSelectedMessageAttachments] = useState<
    IOutlookMessageAttachment[]
  >([]);
  const [selectedDriveItems, setSelectedDriveItems] = React.useState<
    IDriveItem[]
  >([]);
  const [selectedItems, setSelectedItems] = React.useState<
    (IDriveItem | IOutlookMessageAttachment)[]
  >([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState<string | null>(
    null
  );
  const [rows = [], setRows] = useState<any[]>();
  const [errors, setErrors] = useState<any>();

  const {
    data: connections = [],
    isSuccess: sharepointConnectionsLoaded,
    refetch: refetchSharePointConnections,
    isError: sharepointConnectionsError,
    error: sharepointConnectionsErrorObject,
  } = useGetSharePointConnectionsQuery(); // skip if source !== sharepoint

  const [
    getRecentOneDriveItems,
    {
      data: recentOneDriveItems = [],

      isLoading: oneDriveLoading,
      isError: oneDriveError,
      error: oneDriveErrorObject,
    },
  ] = useLazyGetRecentOneDriveFilesQuery();

  const [
    getSharedWithMeItems,
    {
      data: sharedWithMeItems = [],

      isLoading: sharedWithMeLoading,
      isError: sharedWithMeError,
      error: sharedWithMeErrorObject,
    },
  ] = useLazyGetSharedWithMeFilesQuery();

  const [
    getJoinedTeams,
    {
      data: joinedTeams = [],

      isError: teamsError,
      error: teamsErrorObject,
    },
  ] = useLazyGetJoinedTeamsQuery();

  const [getOutlookMessagesCount, { data: outlookMessagesCount }] =
    useLazyGetOutlookMessagesCountQuery();

  const [
    getOutlookMessages,
    {
      data: outlookMessages = [],

      isSuccess: outlookLoaded,
      isError: outlookError,
      error: outlookErrorObject,
    },
  ] = useLazyGetOutlookMessagesQuery();

  useEffect(() => {
    if (source === Source.SharePoint) {
      refetchSharePointConnections();
      // getSharePointSites();
    } else if (source === Source.OneDrive) {
      // refetch the OneDrive recent files if they haven't been fetched yet
      getRecentOneDriveItems();
    } else if (source === Source.SharedWithMe) {
      // refetch the OneDrive shared with me files if they haven't been fetched yet
      getSharedWithMeItems();
    } else if (source === Source.Teams) {
      getJoinedTeams();
    } else if (source === Source.Outlook) {
      getOutlookMessagesCount();
      getOutlookMessages();
    } else if (!source) {
      setSource(Source.OneDrive);
    }
  }, [source]);

  useEffect(() => {
    if (source === Source.Outlook && outlookMessages.length > 0) {
      const messagesWithData = outlookMessages?.map(
        (message: IOutlookMessage) => {
          return outlookMessagesToAttachments(message);
        }
      );
      setRows(messagesWithData);
    }
  }, [outlookMessages]);

  useEffect(() => {
    if (source === Source.OneDrive && recentOneDriveItems.length > 0) {
      setRows(recentOneDriveItems);
    }
  }, [recentOneDriveItems, source]);

  useEffect(() => {
    if (source === Source.SharedWithMe && sharedWithMeItems.length > 0) {
      setRows(sharedWithMeItems);
    }
  }, [sharedWithMeItems, source]);

  useEffect(() => {
    const allItems = [...selectedDriveItems, ...selectedMessageAttachments];

    setSelectedItems(allItems);
  }, [selectedDriveItems, selectedMessageAttachments]);

  const importFromOneDrive = async (driveItems: IDriveItem[]) => {
    // the document needs to be saved in the blob storage since OneDrive is a personal storage

    const importItems: IDocument[] = driveItems?.map((driveItem) => {
      const documentToImport: IDocument = {
        name: driveItem?.name,
        type: "contract",
        origin: "onedrive",
        driveId: driveItem?.parentReference.driveId,
        driveItemId: driveItem.id,
        size: driveItem.size,
        contractId: undefined,
      };

      return documentToImport;
    });

    if (props.onSuccess) {
      props.onSuccess(importItems);

      setTimeout(() => {
        handleCancelClick();
      }, 500);
    }
  };

  const importFromSharePoint = async (driveItems: IDriveItem[]) => {
    // add a pointer for the document
    // the document itself will remain in SharePoint, but we need to store the metadata in the database
    const importItems: IDocument[] = driveItems?.map((driveItem) => {
      const documentToImport: IDocument = {
        name: driveItem?.name,
        type: "contract",
        origin: "sharepoint",
        driveId: driveItem?.parentReference.driveId,
        driveItemId: driveItem.id,
        contractId: undefined,
        size: driveItem.size,
      };

      return documentToImport;
    });

    if (props.onSuccess) {
      props.onSuccess(importItems);

      setTimeout(() => {
        handleCancelClick();
      }, 500);
    }
  };

  const importFromTeams = async (driveItems: IDriveItem[]) => {
    // add a pointer for the document
    // the document itself will remain in Teams, but we need to store the metadata in the database
    const importItems: IDocument[] = driveItems?.map((driveItem) => {
      const documentToImport: IDocument = {
        name: driveItem?.name,
        type: "contract",
        origin: "teams",
        driveId: driveItem?.parentReference.driveId,
        driveItemId: driveItem.id,
        contractId: undefined,
        size: driveItem.size,
      };

      return documentToImport;
    });

    if (props.onSuccess) {
      props.onSuccess(importItems);

      setTimeout(() => {
        handleCancelClick();
      }, 500);
    }
  };

  const importFromOutlook = async () => {
    const docs = selectedMessageAttachments?.map((attachment) => {
      // add a pointer for the document
      // the document itself will remain in SharePoint, but we need to store the metadata in the database
      const documentToImport: Omit<IDocument, "contractId"> = {
        ...attachment,
        name: attachment?.name,
        type: "contract",
        origin: "outlook",
        messageId: attachment.messageId,
        attachmentId: attachment.id,
        size: attachment.size,
      };

      return documentToImport;
    });

    if (props.onSuccess) {
      props.onSuccess(docs);
    }

    setTimeout(() => {
      handleCancelClick();
    }, 500);
  };

  const handleImportClick = async () => {
    switch (source) {
      case Source.OneDrive:
        if (selectedDriveItems && selectedDriveItems?.length > 0) {
          importFromOneDrive(selectedDriveItems);
        }
        break;
      case Source.SharedWithMe:
        break;
      case Source.SharePoint:
        if (selectedDriveItems && selectedDriveItems?.length > 0) {
          importFromSharePoint(selectedDriveItems);
        }
        break;
      case Source.Teams:
        if (selectedDriveItems && selectedDriveItems?.length > 0) {
          importFromTeams(selectedDriveItems);
        }
        break;
      case Source.Outlook:
        if (
          selectedMessageAttachments &&
          selectedMessageAttachments.length > 0
        ) {
          importFromOutlook();
        }
        break;
    }
  };

  const handleCancelClick = () => {
    props.onClose && props.onClose();
  };

  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSourceClick = (s: Source): void => {
    setRows([]);
    setSource(s);
  };

  const addDriveItemsToSelection = (moreDriveItems: IDriveItem[]) => {
    const newDriveItems = moreDriveItems.filter(
      (driveItem) =>
        !selectedDriveItems.some((item) => item.id === driveItem.id)
    );

    setSelectedDriveItems((prev) => [...prev, ...newDriveItems]);
  };

  return (
    <>
      {/* <MegaButton
        actionTitle={t("Import")!}
        actionSubtitle={t("from Microsoft 365")!}
        subtitle={t("Import_applications")!}
        illustration="/static/img/megabutton/Import.png"
        onClick={() => setOpen(true)}
      /> */}
      <Dialog
        open={props.open || false}
        maxWidth="lg"
        fullWidth
        fullScreen
        sx={{ padding: 10, marginX: 30, marginY: 15 }}
        onClose={() => props.onClose}
        aria-labelledby="form-dialog-title"
        // PaperProps={{
        //   style: {
        //     minHeight: "90vh",
        //   },
        // }}
      >
        <DialogTitle id="form-dialog-title">{t("New document")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Upload a new document")}</DialogContentText>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Typography variant="h6" gutterBottom>
                {user?.displayName}
              </Typography>

              <Typography variant="body1" gutterBottom>
                {t("Connected to")}:<br />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  title={user?.email}
                >
                  {user?.email}
                </Typography>
              </Typography>

              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSourceClick(Source.OneDrive)}
                    selected={source === Source.OneDrive}
                  >
                    <ListItemIcon>
                      <OneDriveIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("OneDrive")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSourceClick(Source.SharedWithMe)}
                    selected={source === Source.SharedWithMe}
                  >
                    <ListItemIcon>
                      <OneDriveIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Shared with me")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSourceClick(Source.SharePoint)}
                    selected={source === Source.SharePoint}
                  >
                    <ListItemIcon>
                      <SharePointIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("SharePoint")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSourceClick(Source.Teams)}
                    selected={source === Source.Teams}
                  >
                    <ListItemIcon>
                      <MSTeamsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Teams")} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleSourceClick(Source.Outlook)}
                    selected={source === Source.Outlook}
                  >
                    <ListItemIcon>
                      <OutlookIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("Outlook")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>

            <Grid size={{ xs: 12, md: 8 }}>
              <Grid container gap={3} flexDirection="column">
                <Grid>
                  <Typography variant="h6" gutterBottom>
                    {source === Source.OneDrive && t("OneDrive")}
                    {source === Source.SharedWithMe && t("Shared with me")}
                    {source === Source.SharePoint && (
                      <Grid
                        justifyContent="space-between"
                        container
                        spacing={10}
                      >
                        <Grid>
                          <Typography variant="h6" gutterBottom>
                            {t("SharePoint")}
                          </Typography>
                        </Grid>
                        <Grid>
                          <SharePointConnectionDialog mode={DialogMode.Add} />
                        </Grid>
                      </Grid>
                    )}
                    {source === Source.Teams && t("Teams")}
                    {source === Source.Outlook && t("Outlook")}
                  </Typography>
                  <br />
                </Grid>
                {/* 
                {source &&
                  errors[source]?.length > 0 &&
                  errors[source]?.map((error: any) => (
                    <Grid>
                      <Alert severity="error">
                        <AlertTitle>{error?.data?.error?.code}</AlertTitle>
                        {error?.data?.error?.message}
                      </Alert>
                    </Grid>
                  ))} */}

                {source === Source.SharePoint && sharepointConnectionsError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(sharepointConnectionsErrorObject)}
                    </Alert>
                  </Grid>
                )}

                {source === Source.SharedWithMe && sharedWithMeError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(sharedWithMeErrorObject)}
                    </Alert>
                  </Grid>
                )}

                {source === Source.OneDrive && oneDriveError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(oneDriveErrorObject)}
                    </Alert>
                  </Grid>
                )}

                {source === Source.Teams && teamsError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(teamsErrorObject)}
                    </Alert>
                  </Grid>
                )}

                {source === Source.Outlook && outlookError && (
                  <Grid>
                    <Alert severity="error">
                      <AlertTitle>{t("An error occurred!")}</AlertTitle>
                      {JSON.stringify(outlookErrorObject)}
                    </Alert>
                  </Grid>
                )}

                <Grid size={12}>
                  <TextField // Search field
                    variant="outlined"
                    label={t("Search")}
                    fullWidth
                    // value={searchText}
                    // onChange={(event) => {
                    //   setSearchText(event.target.value);
                    // }}
                    sx={{ mb: 2 }}
                  />

                  {source === Source.Outlook && (
                    <Typography variant="body1" gutterBottom>
                      {t("messages with attachments found", {
                        count: outlookMessagesCount,
                      })}
                    </Typography>
                  )}
                </Grid>

                <Grid>
                  {/* <Typography variant="body1" gutterBottom>
                      {t("No documents found")}
                    </Typography> */}

                  <TableContainer sx={{ maxHeight: "100%" }}>
                    <Table stickyHeader size="small">
                      {/* <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>{t("Site")}</TableCell>
                            <TableCell>{t("Library")}</TableCell>
                          </TableRow>
                        </TableHead> */}

                      {source === Source.OneDrive && (
                        <>
                          <DriveItemHeader
                            onSelectAll={() =>
                              addDriveItemsToSelection(
                                recentOneDriveItems?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              )
                            }
                            onUnselectAll={() =>
                              setSelectedDriveItems((prev) =>
                                prev.filter(
                                  (item) =>
                                    !recentOneDriveItems
                                      ?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                      .includes(item)
                                )
                              )
                            }
                            isAllSelected={recentOneDriveItems
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .every((item) =>
                                selectedDriveItems.includes(item)
                              )}
                          />

                          <TableBody>
                            {recentOneDriveItems
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((driveItem: IDriveItem, i: number) => (
                                <DriveItemRow
                                  key={`driveitem-${i}`}
                                  driveItem={driveItem}
                                  source={source!}
                                  isSelected={selectedDriveItems.some(
                                    (s) => s.id === driveItem.id
                                  )}
                                  setSelectedDriveItems={setSelectedDriveItems}
                                />
                              ))}
                          </TableBody>
                        </>
                      )}

                      {source === Source.SharedWithMe && (
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("File name")}</TableCell>
                              <TableCell>{t("Modified")}</TableCell>
                              {/* <TableCell>{t("Size")}</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sharedWithMeItems
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((driveItem: IDriveItem, i: number) => (
                                <DriveItemRow
                                  key={`sharedwithmeitem-${i}`}
                                  driveItem={driveItem}
                                  source={source!}
                                  isSelected={selectedDriveItems.some(
                                    (s) => s.id === driveItem.id
                                  )}
                                  setSelectedDriveItems={setSelectedDriveItems}
                                />
                              ))}
                          </TableBody>
                        </>
                      )}

                      {source === Source.SharePoint && (
                        <TableBody>
                          {connections
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map(
                              (
                                connection: ISharePointConnection,
                                i: number
                              ) => (
                                <SharePointRow
                                  key={`connection-${i}`}
                                  connection={connection}
                                  source={source!}
                                  setSelectedDriveItems={setSelectedDriveItems}
                                  selectedDriveItems={selectedDriveItems}
                                />
                              )
                            )}
                        </TableBody>
                      )}

                      {source === Source.Teams && (
                        <TableBody>
                          {joinedTeams
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((team: any, i: number) => (
                              <TeamsRow
                                key={`team-${i}`}
                                team={team}
                                source={source!}
                                isSelected={selectedDriveItems.some(
                                  (s) => s.id === team.id
                                )}
                                setSelectedDriveItems={setSelectedDriveItems}
                              />
                            ))}
                        </TableBody>
                      )}

                      {source === Source.Outlook && (
                        <>
                          <TableHead>
                            <TableRow>
                              <TableCell />
                              <TableCell>{t("Date")}</TableCell>
                              <TableCell>{t("Received from")}</TableCell>
                              <TableCell>{t("Subject")}</TableCell>
                            </TableRow>
                          </TableHead>

                          {outlookMessages
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((message: IOutlookMessage, i: number) => (
                              <OutlookRow
                                key={`message-${i}`}
                                message={message}
                                selectedAttachments={
                                  selectedMessageAttachments!
                                }
                                setSelectedAttachments={
                                  setSelectedMessageAttachments
                                }
                              />
                            ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid>
                  {/* <Pagination
                    // siblingCount={0}
                    // boundaryCount={0}
                    count={numPages}
                    color="secondary"
                    page={page}
                    onChange={handleChange}
                  /> */}

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleImportClick} variant="contained">
            {selectedItems?.length === 1
              ? t("Import1File")
              : t("ImportXFiles", {
                  count: selectedItems?.length,
                })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImportDialog;
