import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DocumentViewer from "../../../components/viewers/DocumentViewer";
import useAuth from "../../../hooks/useAuth";
import useContract from "../../../hooks/useContract";
import {
  useGetContractQuery,
  useGetContractsQuery,
  useGetDocumentsQuery,
  useUpdateContractMutation,
} from "../../../redux/slices/indexApiSlice";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../types/contract";
import { DialogMode } from "../../../types/dialogmode";
import { IDocument } from "../../../types/document";
import { ISignature, ISignee } from "../../../types/signature";
import { IGraphUser } from "../../../types/user";
import AIContractDetailSidebar from "./components/AIContractDetailSidebar";
import ContractHeader from "./components/ContractHeader";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  HighlightAreaExtend,
  ILocationInText,
  IPrompt,
} from "../../../types/prompt";
import ContractStatusStepper from "./components/ContractStatusStepper";
import AnalyzingContractsLoader from "../../../components/AnalyzingContractsLoader";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AIContractDetail = ({
  selectedContractId,
  selectedPrompt,
  hideHeader,
}: {
  selectedContractId?: string;
  selectedPrompt?: IPrompt;
  hideHeader?: boolean;
}) => {
  const { t } = useTranslation();
  const { user, method } = useAuth();
  const { clientId, contractId, pageMode, status } = useParams();
  const { requestDocumentAnalysis, analyzedContract, stopDocumentAnalysis } =
    useContract();
  const navigate = useNavigate();

  const users = useSelector((state: RootState) => state.users.users);

  let interval: NodeJS.Timeout | null = null;

  const { data: contracts, isSuccess: contractsLoaded } = useGetContractsQuery(
    status as ContractStatus
  );

  const {
    data: contract,
    isLoading: contractLoading,
    isSuccess: contractLoaded,
    refetch: refetchContract,
    isError,
  } = useGetContractQuery((contractId || selectedContractId) ?? skipToken);

  // get the documents for the contract
  const { data: documents = [] } = useGetDocumentsQuery(
    (contractId || selectedContractId) ?? skipToken
  );

  useEffect(() => {
    if (analyzedContract?.analyzeStatus === AIReadingStatus.COMPLETED) {
      // analysis done, refetch the contract
      refetchContract();
    }
  }, [analyzedContract?.analyzeStatus]);

  const [mode, setMode] = useState<DialogMode>(DialogMode.ViewOnly);

  const [document, setDocument] = useState<IDocument>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [analysisStatus, setAnalysisStatus] = useState<AIReadingStatus>(
    AIReadingStatus.NONE
  );
  // const [initialContract, setInitialContract] = useState<IContract>();
  const [signer, setSigner] = useState<ISignee>();
  const [signees, setSignees] = useState<Array<ISignee>>([]);
  const [signatures, setSignatures] = useState<Array<ISignature>>([]);
  const [reloadSignatures, setReloadSignatures] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchForText, setSearchForText] = useState<string>("");
  const [prompt, setPrompt] = useState<IPrompt | null>(null);
  const [locationInText, setLocationInText] = useState<ILocationInText[]>([]);
  const [highlightAreaExtend, setHighlightAreaExtend] =
    useState<HighlightAreaExtend>();

  // For some reason states (contract, signatures) is not updated
  // in saving functions (handleSaveAsDraft and handleSaveAndPublish).
  // So I use useRef to solve this problem.
  const updatedContractDataRef = useRef<{
    contract: IContract | undefined;
    signatures: ISignature[];
  }>({ contract: undefined, signatures: [] });

  updatedContractDataRef.current = {
    contract: contract ? { ...contract } : undefined,
    signatures: [...signatures],
  };

  useEffect(() => {
    if (documents.length > 0) {
      setDocument(documents[0]);
    }
  }, [documents]);

  useEffect(() => {
    if (contract) {
      const newSignees = contract.signatures?.map((sig: ISignature) => {
        const signee: ISignee = {
          displayName: sig.displayName,
          email: sig.email,
          hasSigned: !!sig.signedDate,
          signatureId: sig.id,
          isApproved: sig.isApproved,
        };
        return signee;
      });

      if (newSignees) {
        setSignees(newSignees);
      }

      const newSignatures = contract.signatures?.map((sig: ISignature) => ({
        ...sig,
        draggable: !sig.isSigned,
      }));

      if (newSignatures) {
        setSignatures(newSignatures);
      }

      if (contract.analyzeStatus && contract.analyzeStatus !== analysisStatus) {
        setAnalysisStatus(contract.analyzeStatus);
      }

      upLocationInTex();
    }
  }, [contract, reloadSignatures, contractId, selectedContractId]);

  useEffect(() => {
    if (isError) {
      navigate("/404");
    }
  }, [isError]);

  useEffect(() => {
    if (pageMode) {
      if (pageMode === "edit") {
        setMode(DialogMode.Edit);
      }
    }
  }, [pageMode]);

  useEffect(() => {
    // hide analysis alert (success or failed)
    if (
      mode === DialogMode.ViewOnly &&
      (analysisStatus === AIReadingStatus.COMPLETED ||
        analysisStatus === AIReadingStatus.ERROR)
    ) {
      setAnalysisStatus(AIReadingStatus.NONE);
    }
  }, [mode]);

  useEffect(() => {
    if (selectedPrompt) {
      setPrompt(selectedPrompt);
    }
  }, [selectedPrompt]);

  useEffect(() => {
    if (
      !contractId &&
      !selectedContractId &&
      !contractLoading &&
      contracts &&
      contracts?.length > 0
    ) {
      // if no id is provided, redirect to the first contract
      navigate(`/contracts/${contracts[0].id}`);
    }

    if (
      contractId &&
      !selectedContractId &&
      !contractLoading &&
      contracts &&
      contracts?.length > 0
    ) {
      const currentIndex = contracts?.findIndex(
        (c: IContract) => c.id === contractId
      );

      setCurrentIndex(currentIndex);
    }
  }, [contractId, selectedContractId, contractLoading, contracts]);

  useEffect(() => {
    if (contract && contract.signatures) {
      const userHaveSignature = contract.signatures.find(
        (sig: ISignature) => sig.email === user.email
      );
      console.log("userHaveSignature", userHaveSignature);
      if (userHaveSignature) {
        setSigner({
          displayName: user.displayName,
          email: user.email,
          hasSigned: !!userHaveSignature.signedDate,
          signatureId: userHaveSignature.id,
        });
      }
    }
  }, [contract, user]);

  useEffect(() => {
    if (analyzedContract?.analyzeStatus === AIReadingStatus.COMPLETED) {
      refetchContract();
    }
  }, [analyzedContract, contractId]);

  const prev = () => {
    if (contracts && contracts.length > 0) {
      const prevContractId = contracts[currentIndex - 1]?.id || null;

      if (prevContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${prevContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${prevContractId}/${mode}`);
        }
      }
    }
  };

  const next = async () => {
    if (contracts && contracts.length > 0) {
      // get the id of the next contract

      const nextContractId = contracts[currentIndex + 1]?.id || null;

      if (nextContractId) {
        if (clientId) {
          navigate(
            `/contracts-under-management/${clientId}/contract/${nextContractId}/${mode}`
          );
        } else {
          navigate(`/contract/${nextContractId}/${mode}`);
        }
      }
    }
  };

  const handleAnalysis = () => {
    if (document) {
      requestDocumentAnalysis(document);

      setShowSnackbar(true);
    }
  };

  const storeUsers = (): IGraphUser[] => {
    let userStore: IGraphUser[] = [];

    // add the add users to a new contract, so send notification and mail of expired contact.
    if (contract && method !== "Google" && users && users.length) {
      // If method is Google then we stored this user by login step
      let userIds: string[] =
        (contract && contract.ownerIds) || (contract && contract.managerIds)
          ? [...contract?.ownerIds, ...contract?.managerIds]
          : [];

      const userIdsUnique = userIds?.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      userStore = users
        .filter((u: IGraphUser) =>
          userIdsUnique.some((id: string) => id == u.id)
        )
        .map((item: IGraphUser) => {
          item = { ...item, mail: item.mail || item.userPrincipalName };
          return item;
        });
    }

    return userStore;
  };

  const upLocationInTex = () => {
    let locationInTextTemp: ILocationInText[] = [];
    contract?.extraContext?.forEach((p) => {
      if (p.locationInText) {
        p.locationInText?.forEach((item) => {
          let newLocationInText: ILocationInText = {
            key: p.property,
            text: item,
          };
          locationInTextTemp.push(newLocationInText);
        });
      }
    });
    setLocationInText(locationInTextTemp);
  };

  const handleClose = () => {
    setShowSnackbar(false);
  };

  const isUpSm = window.innerWidth > 600;

  return (
    <React.Fragment>
      {hideHeader ? null : (
        <>
          <Helmet title={contract?.name} />

          <Grid justifyContent="space-between" container wrap="nowrap">
            <Grid size={{ xl: 10 }}>
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/contracts" color="inherit">
                  <Typography variant="h3" gutterBottom display="inline">
                    {t("Contracts")}
                  </Typography>
                </Link>

                {status && (
                  <Link
                    component={NavLink}
                    to={`/contracts/${status}`}
                    color="inherit"
                  >
                    <Typography variant="h3" gutterBottom display="inline">
                      {t(status)}
                    </Typography>
                  </Link>
                )}

                <Typography
                  variant="h3"
                  gutterBottom
                  display="inline"
                  color="primary"
                >
                  {contract?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>

            {contractsLoaded && (
              <Grid size={{ sm: 12, xl: 2 }}>
                <Grid container justifyContent="end" direction="row" gap={3}>
                  <Grid>
                    <Button
                      onClick={prev}
                      variant="contained"
                      disabled={currentIndex === 0}
                    >
                      <ChevronLeft />
                    </Button>
                  </Grid>

                  <Grid>
                    <Button
                      onClick={next}
                      variant="contained"
                      disabled={currentIndex === contracts?.length - 1}
                    >
                      <ChevronRight />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Link component={NavLink} to="/contracts">
          {t("Contracts")}
        </Link>
        <Typography>{contract?.name}</Typography>
      </Breadcrumbs> */}

          <Divider my={6} />

          {contract?.status && (
            <Box px={6} mb={6} maxWidth="md">
              <ContractStatusStepper contract={contract} />
            </Box>
          )}
        </>
      )}

      <Paper
        sx={{
          marginTop: 0,
          marginBottom: 6,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
          // overflow: isUpSm ? "hidden" : "auto",
        }}
      >
        <Grid container direction="column" gap={6}>
          {/* {contractLoading && (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        )} */}
          {contract && (
            <Box mb={4} display="flex">
              <ContractHeader contract={contract} />
            </Box>
          )}

          {/* only show completed if modified was within the last 5 minutes */}
          {/* {analysisStatus === AIReadingStatus.COMPLETED &&
          isAfter(
            new Date(epochToDate(contract?.modified)),
            new Date(epochToDate(Date.now() - 300000))
          ) && (
            <Alert severity="success" sx={{ mb: 6 }}>
              <AlertTitle>{t("Success")}</AlertTitle>
              {t("Reading success")}
            </Alert>
          )}
         */}

          {analysisStatus === AIReadingStatus.STOPPED && (
            <Alert severity="info" sx={{ mb: 6 }}>
              <AlertTitle>{t("Stopped")}</AlertTitle>
              {t("Analysis stopped")}
            </Alert>
          )}
          {analysisStatus === AIReadingStatus.ERROR && (
            <Alert severity="error" sx={{ mb: 6 }}>
              <AlertTitle>{t("An error occurred!")}</AlertTitle>
              {t("Reading failed")}
            </Alert>
          )}

          <Grid>
            <AnalyzingContractsLoader contract={contract} />
          </Grid>

          <Grid
            container
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            spacing={3}
            pb={6}
            // sx={{ height: "60vh" }}
          >
            <Grid size={{ xs: 12, md: 8 }}>
              {/* {contract && (
              <Box>
                <ContractMetadata contract={contract} />
              </Box>
            )} */}

              <DocumentViewer
                viewMode="withMenu"
                document={document}
                signer={signer}
                signable={false}
                signatures={signatures}
                setSignatures={setSignatures}
                paging={false} // true
                searchForText={searchForText}
                prompt={prompt}
                locationInText={locationInText}
                setHighlightAreaExtend={setHighlightAreaExtend}
                disableAppBar
                // disableScroller
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              {contract && document && (
                <Box height={900}>
                  <AIContractDetailSidebar
                    mode={mode}
                    contract={contract}
                    document={document}
                    signer={signer}
                    signees={signees}
                    onAnalysis={handleAnalysis}
                    setSignees={setSignees}
                    setSignatures={setSignatures}
                    signatures={signatures}
                    resetSignatures={setReloadSignatures}
                    analysisStatus={analysisStatus}
                    setSearchForText={setSearchForText}
                    prompt={prompt || undefined}
                    setPrompt={setPrompt}
                    highlightAreaExtend={highlightAreaExtend}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showSnackbar}
        onClose={handleClose}
        message={t("Analysis requested")}
        key={"bottomright"}
        autoHideDuration={5000}
        color="primary"
      />
    </React.Fragment>
  );
};

export default AIContractDetail;
