import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import {
  useGetContractsQuery,
  useGetSettingsQuery,
  useSyncContractsFromSharePointMutation,
} from "../../../../redux/slices/indexApiSlice";

import DataGridContracts from "../../../../components/datagrids/DataGridContracts";
import { useTranslation } from "react-i18next";
import { DialogMode } from "../../../../types/dialogmode";
import InitiateDocumentsDialog from "../../../../components/dialogs/InitiateDocumentsDialog";
import { useGetDriveItemsQuery } from "../../../../redux/slices/graphApiSlice";
import { IDriveItem } from "../../../../types/driveItem";
import { use } from "i18next";
import { ContractStatus } from "../../../../types/contract";
import AskMeAnything from "../../../components/AskMeAnything";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import DataGridContractPromptResponses from "../../../../components/datagrids/DataGridContractPromptResponses";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function StrengthMatrix() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { status } = useParams();

  const {
    data: contractsAPI = [],
    isLoading,
    isSuccess: contractsLoaded,
    isUninitialized,
  } = useGetContractsQuery(status as ContractStatus);

  // useEffect(() => {
  //   if (status) {
  //     // if status is a guid, we need to redirect to the contract details page
  //     if (status.length === 36) {
  //       navigate(`/contracts/${ContractStatus.DRAFT}/${status}`);
  //     }
  //   }
  // }, [status]);

  // We need a function here that checks if the data location is set to SharePoint, and if it is, is should retrieve all contracts from SharePoint
  // For each contract in SharePoint, it should check if the contract is already in the database. If it is not, it should add it to the database.
  // The driveItemId can be used as a contract Id
  // The driveItemName can be used as a contract name
  // After adding the contract, is should also create a Document object
  // However, the document content remains in SharePoint

  // get the settings
  const { data: settings, isSuccess: settingsLoaded } = useGetSettingsQuery();

  const { data: driveItems, isSuccess: driveItemsLoaded } =
    useGetDriveItemsQuery(
      settings?.dataLocation?.connection?.driveId ?? skipToken
    );

  const [syncContracts, { isLoading: isSyncing, isSuccess: isSynced }] =
    useSyncContractsFromSharePointMutation();

  // if the settings are loaded and the data location is set to SharePoint, we should retrieve all contracts from SharePoint
  useEffect(() => {
    if (settingsLoaded && settings?.dataLocation?.source === "sharepoint") {
      // retrieve all contracts from SharePoint
    }
  }, [settingsLoaded, settings]);

  useEffect(() => {
    if (driveItemsLoaded) {
      console.log(driveItems);

      // Let the BE handle the logic of adding contracts from SharePoint
      // Make an array with all the driveItemIds
      // Send the array to the BE

      const driveItemContracts = driveItems?.map((driveItem: IDriveItem) => {
        // const driveItemContract: any = {
        //   id: driveItem.id,
        //   status: ContractStatus.DRAFT,
        //   name: driveItem.name,
        // };

        // return driveItemContract;
        return driveItem;
      });

      console.log(driveItemContracts);
      // syncContracts(driveItemContracts);
    }
  }, [driveItemsLoaded, driveItems]);

  return (
    <React.Fragment>
      <Helmet title={t("Strength matrix")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Strength matrix")}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <AskMeAnything placeholder={"Ask a question about your contracts"} />
        </Grid>
      </Grid>

      <Divider my={6} />

      <DataGridContractPromptResponses />
    </React.Fragment>
  );
}

export default StrengthMatrix;
