import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import dayjs from "dayjs";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography,
  Button,
  List,
  Paper as MuiPaper,
  Box,
  IconButton,
  useTheme,
  CardActionArea,
  MobileStepper,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import calendarStyle from "./Calendar.style";
import {
  useGetContractsQuery,
  useGetMyNotesQuery,
  useGetNotificationsQuery,
} from "../../../redux/slices/indexApiSlice";
import { ContractStatus, IContract } from "../../../types/contract";
import { format, set } from "date-fns";
import { useTranslation } from "react-i18next";
import { DateInput, EventSourceInput } from "@fullcalendar/core";
import {
  blue,
  blueGrey,
  deepOrange,
  green,
  grey,
  lightBlue,
  red,
  yellow,
} from "@mui/material/colors";
import { subPeriodFromDate } from "../../../utils/string";
import { INote } from "../../../types/note";
import NoteDialog from "../../../components/dialogs/NoteDialog";
import { DialogMode } from "../../../types/dialogmode";
import useAuth from "../../../hooks/useAuth";
import NoteAsListItem from "../../../components/lists/listitems/NoteAsListItem";
import AskMeAnything from "../../components/AskMeAnything";
import { ca } from "date-fns/locale";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { INotification } from "../../../types/notification";
import ContractExpiryNoteAsListItem from "../../../components/lists/listitems/ContractExpiryNoteAsListItem";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const Paper = styled(MuiPaper)(spacing);

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface ICalendarProps {
  events: EventSourceInput;
  onSelectDate: (date: Date) => void;
  notes?: INote[];
  refetchNotes?: () => void;
}

function Calendar({ ...props }: ICalendarProps) {
  const { i18n, t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();

  const [forceOpen, setForceOpen] = React.useState(false);
  const [hoverDate, setHoverDate] = React.useState<Date | null>(null);
  const [selectedNote, setSelectedNote] = React.useState<INote | null>(null);

  const calendarRef = useRef(null);

  useEffect(() => {
    if (id && props.notes && props.notes.length > 0) {
      // load the month of the note that needs to be displayed
      const note = props.notes?.find((n: INote) => n.id === id);

      if (note && note.reminderDate) {
        changeView("dayGridMonth");
        goToDate(new Date(note.reminderDate));
      }
    }
  }, [id, props.notes]);

  // Function to change the view
  const changeView = (viewName: string) => {
    if (calendarRef.current) {
      const calendarApi = (calendarRef.current as any).getApi(); // Access FullCalendar API
      calendarApi.changeView(viewName); // Change the view
    }
  };

  const goToDate = (date: Date) => {
    if (calendarRef.current) {
      const calendarApi = (calendarRef.current as any).getApi(); // Access FullCalendar API
      calendarApi.gotoDate(date); // Change the view

      calendarApi.select(date);
    }
  };

  const handleDayDblClick = (info: any) => {
    if (info.jsEvent.detail !== 2) {
      return;
    }

    setSelectedNote(null);
    setForceOpen(false);

    setTimeout(() => {
      // console.log(info);

      setForceOpen(true);
    }, 100);
  };

  const handleDaySelect = (info: any) => {
    console.log(info);
    setSelectedNote(null);
    setHoverDate(info.start);

    props.onSelectDate(info.start);
  };

  const handleEventClick = (info: any) => {
    setForceOpen(false);

    console.log(info);

    const note = props.notes?.find((n) => n.id && n.id === info.event.id);

    if (note && note.reminderDate) {
      setSelectedNote(note);
      setHoverDate(new Date(note.reminderDate));

      setTimeout(() => {
        // console.log(info);

        setForceOpen(true);
      }, 100);
    }
  };

  const translateButtonText = (key: string) => {
    return t(key).toLocaleLowerCase() || key;
  };

  return (
    <Card mb={6}>
      <CardContent
        p={6}
        sx={{
          "& .fc-event-title-container": {
            whiteSpace: "normal",
            padding: 1,
          },
        }}
      >
        <FullCalendarWrapper>
          <FullCalendar
            ref={calendarRef} // Assign the ref to FullCalendar
            initialView="dayGridMonth" // "multiMonthFourMonth" // "dayGridMonth"
            multiMonthMaxColumns={1}
            plugins={[dayGridPlugin, interactionPlugin, multiMonthPlugin]}
            events={props.events}
            eventDisplay="block" // block, auto
            displayEventTime={false}
            height="auto"
            expandRows={true}
            themeSystem="standard"
            editable={true}
            selectable
            headerToolbar={{
              left: "prevYear,prev,next,nextYear,today",
              center: "title",
              right:
                "dayGridDay,dayGridWeek,dayGridMonth,multiMonthFourMonth,multiMonthYear", // multiMonthYearBig
            }}
            buttonHints={{
              prev: translateButtonText("Previous"),
              next: translateButtonText("Next"),
              prevYear: translateButtonText("Previous year"),
              nextYear: translateButtonText("Next year"),
              year: translateButtonText("Year"),
              today: translateButtonText("Today"),
            }}
            buttonText={{
              today: translateButtonText("Today"),
              dayGridDay: translateButtonText("Today"),
              dayGridWeek: translateButtonText("week"),
              dayGridMonth: translateButtonText("month"),
              multiMonthFourMonth: translateButtonText("quarter"),
              multiMonthYear: translateButtonText("year"),
              multiMonthYearBig: translateButtonText(
                "upcoming_months_detailed"
              ),
            }}
            locale={i18n.language}
            eventColor="red"
            select={handleDaySelect}
            dateClick={(info: any) => {
              if (info.jsEvent.detail === 2) {
                handleDayDblClick(info);
              }
            }}
            eventAdd={(info) => {
              console.log(info);
            }}
            eventClick={handleEventClick}
            views={{
              dayGridWeek: {
                eventDisplay: "block",
              },
              multiMonthFourMonth: {
                eventDisplay: "auto",
                titleFormat: {
                  year: "numeric",
                  month: "short",
                },
                type: "multiMonth",
                duration: { months: 4 },
                multiMonthMaxColumns: 2,
              },
              multiMonthYear: {
                multiMonthMaxColumns: 3,
              },
              multiMonthYearBig: {
                type: "multiMonth",
                duration: { months: 12 },
                multiMonthMaxColumns: 1,
              },
            }}
          />

          {hoverDate && forceOpen && (
            <NoteDialog
              open={forceOpen}
              mode={selectedNote?.id ? DialogMode.Edit : DialogMode.Add}
              type={
                selectedNote?.contractId || selectedNote?.vendorId
                  ? "note"
                  : "reminder"
              }
              controlled={true}
              note={
                selectedNote || {
                  body: "",
                  author: user ? user?.id : null,
                  taggedUsers: user ? [user?.id] : [],
                  reminderDate: hoverDate.toISOString(),
                }
              }
            />
          )}
        </FullCalendarWrapper>
      </CardContent>
    </Card>
  );
}

function ContractCalendar({ ...props }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [events = [], setEvents] = useState<EventSourceInput>([]);
  const [sidebarItems, setSidebarItems] = React.useState<(INote | IContract)[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const pageSize = 3;

  const { data: contracts = [], isSuccess: contractsLoaded } =
    useGetContractsQuery(ContractStatus.ALL);

  const {
    data: notes = [],
    isLoading: notesLoading,
    isSuccess: notesLoaded,
    refetch: refetchNotes,
  } = useGetMyNotesQuery(true);

  useEffect(() => {
    const numPages = Math.ceil(sidebarItems.length / pageSize);
    setNumPages(numPages);
  }, [sidebarItems?.length]);

  useEffect(() => {
    if (contractsLoaded && notesLoaded && !notesLoading) {
      const noticeDateEvents: EventSourceInput = contracts
        ?.filter(
          (contract: IContract) => contract.endDate && contract.noticePeriod
        )
        .map((contract: IContract) => {
          const noticeDate =
            contract.endDate && contract.noticePeriod
              ? subPeriodFromDate(
                  new Date(contract.endDate),
                  contract.noticePeriod
                )
              : null;

          const unit = contract?.noticePeriod?.unit || "unknown";

          const date: DateInput = noticeDate
            ? new Date(noticeDate)
            : new Date();

          return {
            title: `${contract.name} ${t("expires in")} ${
              contract?.noticePeriod?.value
            } ${t(unit)}`,
            start: date,
            url: `/contract/${contract.id}`,
            color: yellow[900],
          };
        });

      const endDateEvents: EventSourceInput = contracts
        ?.filter((c) => c.endDate)
        .map((contract: IContract) => {
          const date: DateInput = contract.endDate
            ? new Date(contract.endDate)
            : new Date();

          return {
            title: `${contract.name} ${t("expires")}`,
            start: date,
            url: `/contract/${contract.id}`,
            color: red[700],
          };
        });

      const noteEvents: EventSourceInput = notes
        ?.filter((n) => n.reminderDate)
        .map((note: INote) => {
          const date: DateInput = note.reminderDate
            ? new Date(note.reminderDate)
            : new Date();

          return {
            id: note?.id,
            title: note.body,
            start: date,
            url: note.contractId
              ? `/contract/${note.contractId}`
              : note.vendorId
              ? `/vendor/${note.vendorId}`
              : "#",
            color: grey[800],
          };
        });

      const allEvents = [...noticeDateEvents, ...endDateEvents, ...noteEvents];

      setEvents(allEvents);

      const contractsNotExpired = contracts.filter(
        (c) => c.endDate && new Date(c.endDate) > new Date()
      );

      // sort notes by reminder date ASC

      const sorted = [...notes, ...contractsNotExpired]
        ?.filter((n: any) => {
          // if n is a contract
          if (n.endDate) {
            return dayjs(n.endDate).isAfter(dayjs().subtract(14, "days"));
          } else if (n.reminderDate) {
            return dayjs(n.reminderDate).isAfter(dayjs().subtract(14, "days"));
          }

          return false;
        })
        .sort((a: any, b: any) => {
          const dateA = a.reminderDate || a.endDate;
          const dateAA = new Date(dateA);

          const dateB = b.reminderDate || b.endDate;
          const dateBB = new Date(dateB);

          if (dateAA < dateBB) return -1;
          if (dateAA > dateBB) return 1;
          return 0;
        });

      setSidebarItems(sorted);
    }
  }, [contracts, contractsLoaded, notes, notesLoaded, notesLoading]);

  const handleNext = async () => {
    if (currentPage === numPages - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const handleBack = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  return (
    <React.Fragment>
      <Helmet title={t("Calendar")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Calendar")}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <AskMeAnything placeholder={"Ask a question about your events"} />
        </Grid>
      </Grid>

      {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("Dashboard")}
        </Link>
        <Typography>{t("Calendar")}</Typography>
      </Breadcrumbs> */}

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <Grid container gap={3}>
            <Grid>
              <NoteDialog
                mode={DialogMode.Add}
                type="reminder"
                buttonVariant="contained"
                note={{
                  body: "",
                  author: user ? user?.id : null,
                  taggedUsers: user ? [user?.id] : [],
                  reminderDate:
                    selectedDate?.toISOString() || new Date().toISOString(),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={3} size={12}>
          <Grid size={{ md: 12, lg: 8 }}>
            <Calendar
              events={events}
              onSelectDate={setSelectedDate}
              notes={notes}
            />
          </Grid>
          <Grid size={{ md: 12, lg: 4 }}>
            <Card>
              <CardContent
                sx={{
                  background: (props) => props.palette.background.default,
                }}
              >
                <Typography variant="h5">{t("Reminders")}</Typography>
                <MobileStepper
                  variant="dots"
                  steps={numPages}
                  position="static"
                  activeStep={currentPage}
                  sx={{ maxWidth: 400, flexGrow: 1 }}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={currentPage === numPages - 1}
                    >
                      {t("Next")}
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={currentPage === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      {t("Back")}
                    </Button>
                  }
                />

                <List onMouseLeave={() => setHoveredIndex(-1)} disablePadding>
                  {sidebarItems?.map((noteOrContract: any, i: number) => {
                    if (
                      i >= currentPage * pageSize &&
                      i < (currentPage + 1) * pageSize
                    ) {
                      return (
                        <>
                          <Card
                            key={noteOrContract.id}
                            onMouseEnter={() => setHoveredIndex(i)}
                            variant="elevation"
                            sx={{ margin: 3 }}
                            onClick={() =>
                              noteOrContract.endDate
                                ? navigate(`/contract/${noteOrContract.id}`)
                                : navigate(`/calendar/${noteOrContract.id}`)
                            }
                          >
                            <CardActionArea>
                              <CardContent>
                                {noteOrContract.endDate ? (
                                  <ContractExpiryNoteAsListItem
                                    contract={noteOrContract}
                                  />
                                ) : (
                                  <NoteAsListItem
                                    note={noteOrContract}
                                    showMenuIcon={i === hoveredIndex}
                                  />
                                )}
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </>
                      );
                    }
                  })}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ContractCalendar;
